<template>
	<div id="content">
		<shutter-panel ref="shutterPanel" />
	</div>
</template>

<script type="text/javascript">
	import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"

	export default {
		name: 'TiersChecked',
		mixins: [Shutter, ShutterInvoice],
		props: ['tiers_id'],
		data () {
			return {
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			init_component() {
				this.setuptInvoiceSupplierChecked(this.tiers_id)
			}
		},

		components: {
			
		}
	}

</script>